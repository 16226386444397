<template>
  <div class="row mb-4">
    <div class="col-md-2">
      <a-input v-model:value="year" type="number" placeholder="Год" />
    </div>
    <div class="col-md-2">
      <a-select
        v-model:value="month"
        :options="monthsList"
        :option-filter-prop="'label'"
        placeholder="Месяц"
        style="width: 100%"
        show-search
        allow-clear
        class="mb-2 mb-md-0"
      />
    </div>
    <div class="col-md-2">
      <a-button :loading="loading" @click="getData" type="primary">Применить</a-button>
    </div>
  </div>
  <div v-html="content" style="overflow-y: scroll; width: 100%"></div>
</template>

<script setup>
import store from 'store'
import { onMounted, ref } from 'vue'
import apiClient from '@/services/axios'

const monthsList = ref([
  { value: '', label: 'Выберите месяц' },
  { value: '01', label: 'Январь' },
  { value: '02', label: 'Февраль' },
  { value: '03', label: 'Март' },
  { value: '04', label: 'Апрель' },
  { value: '05', label: 'Май' },
  { value: '06', label: 'Июнь' },
  { value: '07', label: 'Июль' },
  { value: '08', label: 'Август' },
  { value: '09', label: 'Сентябрь' },
  { value: '10', label: 'Октябрь' },
  { value: '11', label: 'Ноябрь' },
  { value: '12', label: 'Декабрь' },
])
const year = ref('2023')
const month = ref('01')
const content = ref('')
const loading = ref(false)
// onMounted(() => {
//   getData()
// })
const getData = () => {
  loading.value = true
  content.value = ''
  const accessToken = store.get('accessToken')
  const headers = { 'X-Access-Token': `Bearer ${accessToken}` }
  apiClient.post('/report/analytics', {
    year: year.value,
    month: month.value
  }, { headers }).then(response => {
    content.value = response.data.data
  }).catch(error => {}).finally(() => {
    loading.value = false
  })
}
</script>

<style scoped lang="scss">

</style>
