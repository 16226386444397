<template>
  <div>
    <div class="row">
      <div class="col-md-2">
        <a-input
          v-model:value="storeSearchName"
          placeholder="Поиск по названию"
          @change="searchStore"
          allow-clear
        />
      </div>
      <div class="col-md-2">
        <a-input
          v-model:value="innSearchName"
          placeholder="Поиск по ИНН и названию юр.лица"
          @change="searchByInn"
          allow-clear
        />
      </div>
      <div class="col-md-2">
        <a-select
          v-model:value="brandId"
          :options="brands"
          :option-filter-prop="'label'"
          placeholder="Выберите бренд"
          style="width: 100%"
          @change="searchBrand"
          show-search
          allow-clear
        />
      </div>
      <div class="col-md-6 text-right">
        <a-button
          v-if="user.role === 'admin'"
          type="link"
          class="editable-add-btn"
          @click="posModal = true"
          style="margin-bottom: 16px;margin-right: 10px"
        >Обновить POS_TYPE филиала</a-button>
        <vb-create-store
          :brands="brands"
          :classifications="classifications"
          :cuisines="cuisines"
          :additionals="additionals"
          :stickers="stickers"
          :pos-types="posTypes"
          @reload="reload()"
          v-if="!['manager', 'brandmanager', 'engineer'].includes(user.role)"
        />
      </div>
      <a-modal
        class="pos-modal"
        title="Обновить POS_TYPE филиала"
        :visible="posModal"
        :closable="true"
        :confirm-loading="posLoading"
        @cancel="closePos"
      >
        <template #footer>
          <a-button key="cancel" shape="round" type="secondary" @click="closePos">
            <template #icon>
              <CloseCircleOutlined />
            </template>
            Отменить
          </a-button>
          <a-button
            key="ok"
            shape="round"
            type="primary"
            @click="updatePos"
            :loading="posLoading"
          >
            <template #icon>
              <CheckCircleOutlined />
            </template>
            Обновить POS_TYPE_ID
          </a-button>
        </template>
        <a-form>
          <a-form-item label="Филиал">
            <a-select
              show-search
              :option-filter-prop="'label'"
              v-model:value="pos.store_id"
              :options="storeFilter"
              style="width: 100%"
            />
          </a-form-item>
          <a-form-item label="Тип кассовой системы">
            <a-select
              show-search
              :option-filter-prop="'label'"
              v-model:value="pos.pos_type_id"
              :options="posTypes"
              style="width: 100%"
            />
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
    <vb-edit-store
      :brands="brands"
      :pos-types="posTypes"
      v-if="editModal"
      :store-id="storeId"
      @reload="reload()"
      @hide-modal="hideEditModal()"
    />
    <vb-edit-alt-store
      :brands="brands"
      :pos-types="posTypes"
      v-if="editAltModal"
      :store-id="storeId"
      @reload="reload()"
      @hide-modal="hideEditAltModal()"
    />
    <vb-menu-store
      :brands="brands"
      :pos-types="posTypes"
      v-if="menuModal"
      :store-id="storeId"
      :store-name="storeName"
      @reload="reload()"
      @hide-modal="hideMenuModal()"
    />
    <vb-multiple-edit-store
        v-if="multipleModalVisible"
        :key="multipleEditModalKey"
        :store-ids="selectedRowKeys"
        @reload="reload()"
        @hide-modal="hideMultipleModal()"
        :pos-types="posTypes"
        :brands="brands"
    />
    <vb-multiple-menu-store
      v-if="multipleMenuModalVisible"
      :key="multipleMenuModalKey"
      :store-ids="selectedRowKeys"
      :cuisines="cuisines"
      :classifications="classifications"
      :additionals="additionals"
      :stickers="stickers"
      @reload="reload()"
      @hide-modal="hideMenuModal()"
      :pos-types="posTypes"
      :brands="brands"
    />
    <vb-withdraw
      v-if="withdrawModal"
      :store-id="storeId"
      :store-name="storeName"
      @reload="reload()"
      @hide-modal="hideWithdrawModal"
    />
    <div class="clearfix">
      <a-dropdown
        class="my-2"
        v-if="selectedRowKeys.length"
      >
        <template #overlay>
          <a-menu>
            <a-menu-item key="1" @click="multipleUpdateModal">
              Редактировать
            </a-menu-item>
            <a-menu-item key="2" @click="multipleMenuModal">
              Настройки филиала
            </a-menu-item>
          </a-menu>
        </template>
        <a-button type="secondary">
          Выберите действие
          <DownOutlined/>
        </a-button>
      </a-dropdown>
      <a-typography-text v-if="selectedRowKeys.length" secondary class="mx-3">
        (Выделено: {{ selectedRowKeys.length }} элементов)
      </a-typography-text>
      <a-button
        v-if="showSelectAllButton"
        @click="selectAll"
        type="link"
      >Выделить все ({{ total }})
      </a-button>
      <a-button
        v-if="selectedRowKeys.length"
        @click="clearSelection"
        type="text"
        class="text-red"
      >
        Отменить
      </a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :row-selection="rowSelection"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="tableChange"
      row-key="id"
      :expand-row-by-click="true"
      :custom-row="(record) => {
        return {
          ondblclick: () => {
            updateModal(record['uuid']);
          }
        }
      }"
    >
<!--      <template #status="{ record }">-->
<!--        <a-tooltip class="cursor-pointer" v-if="record.status == null" title="Нет интергации"><a-tag color="default">Отключен</a-tag></a-tooltip>-->
<!--        <a-tooltip class="cursor-pointer" v-if="record.status === '1'" :title="`Подключен: \n\r ${record['connected_on']}`"><a-tag color="green">Подключен</a-tag></a-tooltip>-->
<!--        <a-tooltip class="cursor-pointer" v-if="record.status === '0'" :title="`Отключен: \n\r ${record['disconnected_on']}`"><a-tag color="red">Отключен</a-tag></a-tooltip>-->
<!--      </template>-->
      <template #name="{ record }">
        <router-link :to="{ name: 'employee', query: { store_id: record.id } }">
          <div
            v-if="record.logo_url !== ''"
            :style="'background-image: url(\'' + record.logo_url + '\')'"
            class="store-logo"
          ></div>
          <span class="store-name">
            <div class="terminal-statuses" @click.prevent="router.push({ name: 'terminal', params: { brand: record.brand_id, store: record.id } })" v-if="showTerminalIds(record)">
              <div v-for="terminal in record.terminals" :key="terminal.id">
                <a-tooltip class="cursor-pointer" v-if="terminal.status == null" :title="`Нет интергации (ID: ${terminal['id']})`">
                  <a-badge status="default" />
                </a-tooltip>
                <a-tooltip class="cursor-pointer" v-if="terminal.status === '1'">
                  <template #title><div class="text-center">Подключен (ID: {{ terminal['id'] }}) <br />{{ terminal['connected_on'] }}</div></template>
                  <a-badge status="processing" />
                </a-tooltip>
                <a-tooltip class="cursor-pointer" v-if="terminal.status === '0'">
                  <template #title><div class="text-center">Нет связи с кассой (ID: {{ terminal['id'] }}) <br />{{terminal['disconnected_on']}}</div></template>
                  <a-badge status="error" />
                </a-tooltip>
                </div>
            </div>
            <span>{{ record.name }}</span>
          </span>
        </router-link>
      </template>
      <template #wallet_type="{ record }">
        {{ walletTypes[record.wallet_type] }}
      </template>
      <template #wallet="{ record }">
        <div v-if="user.role === 'manager'">
          <a
            v-if="record.wallet && record.wallet.type === 'shared'"
            @click="showWithdrawModal(record.id, record.name, user)">
            {{ formattedBalance(record.wallet.balance) }}
          </a>
        </div>
        <div v-else>
          <span v-if="record.wallet && record.wallet.type === 'shared'">
            {{ formattedBalance(record.wallet.balance) }}
          </span>
        </div>
      </template>
      <template #status="{ record }">
        <a-tooltip placement="top">
          <template #title>
            <span v-if="record['status_updated_at']">{{ formattedDatetime(record['status_updated_at']) }}</span>
            <span v-if="record['status_updated']"> ({{ record['status_updated'] }})</span>
          </template>
          <a-popconfirm
            title="Вы уверены что хотите отключить заведение?"
            @confirm="updateStatus(record['id'], 0)"
            @cancel="() => {}"
            ok-text="Да"
            cancel-text="Нет"
            v-if="record.status === '1'"
          >
            <a-tag color="#108ee9" class="cursor-pointer">Активный</a-tag>
          </a-popconfirm>
          <a-popconfirm
            title="Вы уверены что хотите активировать заведение?"
            @confirm="updateStatus(record['id'], 1)"
            @cancel="() => {}"
            ok-text="Да"
            cancel-text="Нет"
            v-if="record.status === '0'"
          >
            <a-tag class="cursor-pointer">Неактивный</a-tag>
          </a-popconfirm>
        </a-tooltip>
      </template>
      <template #date="{ record }">
        <span>
          {{ formattedDatetime(record['added_on']) }}
        </span>
        <span v-if="record.author">
          ({{ record.author }})
        </span>
      </template>
      <template #updated="{ record }">
        <span v-if="record['updated_on']">
          {{ formattedDatetime(record['updated_on']) }}
        </span>
        <span v-if="record.updated">
          ({{ record.updated }})
        </span>
      </template>
      <template #action="{ record }">
        <div class="text-right" v-if="user.role !== 'engineer'">
<!--          <a-dropdown-button class="dropdown-action-button" style="width: 80px">-->
<!--            <template #icon><i class="fe fe-list" style="font-size: 20px; line-height: 40px"></i></template>-->
          <a-dropdown-button class="dropdown-action-button">
            <template #icon><div class="more-icon-border"><i class="fe fe-more-vertical"></i></div></template>
            <template #overlay>
              <a-menu>
                <a-menu-item
                  key="1"
                  @click="showEditModal(record.id)"
                  v-if="!['manager', 'brandmanager'].includes(user.role)"
                >
                  Редактировать филиал
                </a-menu-item>
                <a-menu-item
                  key="1"
                  @click="showEditAltModal(record.id)"
                  v-if="['manager', 'brandmanager'].includes(user.role)"
                >
                  Редактировать филиал
                </a-menu-item>
                <a-menu-item
                  key="2"
                  @click="showMenuModal(record.id, record.name)"
                  v-if="!['manager', 'brandmanager'].includes(user.role)"
                >
                  Настройки меню
                </a-menu-item>
                <a-menu-item
                  key="3"
                >
                  <a-popconfirm
                    :title="'Создать копию заведения ' + storeName + '?'"
                    @confirm="copy(record.id)"
                  >
                    <a href="#">Создать копию</a>
                  </a-popconfirm>
                </a-menu-item>
                <a-menu-item
                  key="4"
                  @click="showInviteConfirm(record.id, record.name)"
                >
                  <a-spin v-if="inviteLoading === record.id" size="small"></a-spin>
                  Отправить приглашение
                </a-menu-item>
                <a-menu-item
                  key="4"
                  @click="goToReports(record.id)"
                  v-if="false"
                >
                  Отчеты
                </a-menu-item>
                <a-menu-item
                  key="5"
                  @click="router.push({ name: 'terminal', params: { brand: record.brand_id, store: record.id } })"
                  v-if="user.role === 'admin'"
                >
                  Кассы
                </a-menu-item>
                <a-menu-item
                  key="6"
                  @click="showTerminalModal(record.id)"
                  v-if="!['manager', 'brandmanager'].includes(user.role)"
                >
                  Импортировать кассу
                </a-menu-item>
                <a-menu-item
                  key="7"
                  @click="goToStores(record.id)"
                  v-if="false"
                >
                  Сотрудники
                </a-menu-item>
                <a-menu-item
                  key="8"
                  @click="router.push({ name: 'orders', params: { store: record.id } })"
                  v-if="false"
                >
                  Заказы
                </a-menu-item>
                <a-menu-item
                  key="9"
                  @click="router.push({ name: 'tables', params: { store: record.id } })"
                  v-if="false"
                >
                  Столы
                </a-menu-item>
                <a-menu-item
                  key="10"
                  @click="router.push({ name: 'hall', params: { store: record.id } })"
                  v-if="false"
                >
                  Залы
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </div>
      </template>
    </a-table>
    <div class="mt-4 text-right">
      <a-pagination
        show-size-changer
        v-if="total > query['per-page'].value"
        v-model:pageSize="query['per-page'].value"
        v-model:current="query.page.value"
        :total="total"
      />
    </div>
    <a-modal
      title="Отправить приглашение"
      v-model:visible="inviteModal"
      @ok="inviteConfirm"
      @cancel="hideInviteConfirm"
    >
      Отправить приглашение всем кто еще не подключился к заведению {{ inviteStoreName }}?
    </a-modal>
    <a-modal
      class="terminal-modal"
      title="Импорт кассы"
      :visible="terminalModal"
      :closable="true"
      :width="600"
      :confirm-loading="terminalLoading"
      @cancel="closeTerminalModal"
    >
      <template #footer>
        <a-button key="cancel" shape="round" type="secondary" @click="closeTerminalModal">
          <template #icon>
            <CloseCircleOutlined />
          </template>
          Закрыть
        </a-button>
        <a-button
          key="ok"
          shape="round"
          type="primary"
          @click="importTerminals"
          :loading="terminalLoading"
        >
          <template #icon>
            <ImportOutlined />
          </template>
          Импорт
        </a-button>
      </template>
      <div v-if="terminalImportData.length">
        <a-form layout="vertical">
          <table>
            <tr v-for="(item, key) in terminalImportData" :key="key">
              <td style="width: 30%">
                <a-form-item name="name" label="Название кассы" class="px-2">
                  <a-input v-model:value="item.name" />
                </a-form-item>
              </td>
              <td style="width: 30%">
                <a-form-item name="device_id" label="Device ID" class="px-2">
                  <a-input v-model:value="item.device_id" />
                </a-form-item>
              </td>
              <td style="width: 30%">
                <a-form-item name="id" label="ID" class="px-2 w-100">
                  <a-select v-model:value="item.id" style="width: 100%">
                    <a-select-option value="new">Новый ID</a-select-option>
                    <a-select-option v-if="item.id !== 'new'" :value="item.id">{{ item.id }}</a-select-option>
                  </a-select>
                </a-form-item>
              </td>
            </tr>
          </table>
        </a-form>
      </div>
      <a-upload-dragger
        v-else
        v-model:fileList="fileList"
        name="file"
        :multiple="true"
        :headers="getHeaders()"
        :action="getImportUrl"
        @change="uploadTerminals"
      >
        <p class="ant-upload-drag-icon">
          <inbox-outlined></inbox-outlined>
        </p>
        <p class="ant-upload-text">Нажмите или перетащите файл в эту область, <br>чтобы загрузить</p>
      </a-upload-dragger>
    </a-modal>
  </div>
</template>

<script setup>
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
  ImportOutlined,
  InboxOutlined
} from "@ant-design/icons-vue";
import {computed, onMounted, reactive, ref, toRefs, unref, watch} from "vue";
import VbMultipleEditStore from "@/views/store/multiple-edit";
import VbMultipleMenuStore from "@/views/store/multiple-menu";
import {message, notification} from "ant-design-vue";
import VbEditAltStore from "@/views/store/edit-alt";
import VbMenuStore from "@/views/store/edit-menu";
import VbWithdraw from "@/views/store/withdraw";
import VbCreateStore from "@/views/store/create";
import { useRoute, useRouter } from 'vue-router';
import { default as localStore } from "store";
import { getHeaders } from "@/helpers/header";
import VbEditStore from "@/views/store/edit";
import apiClient from "@/services/axios";
import { useStore } from "vuex";

const showSelectAllButton = ref(false),
  multipleEditModalKey = ref(0),
  multipleMenuModalKey = ref(0),
  storeName = ref(''),
  storeSearchName = ref(''),
  innSearchName = ref(''),
  dataSource = ref([]),
  loading = ref(false),
  inviteLoading = ref(0),
  modalKey = ref(0),
  inviteModal = ref(false),
  inviteId = ref(0),
  inviteStoreName = ref(null),
  posLoading = ref(false),
  posModal = ref(false),
  editModal = ref(false),
  editAltModal = ref(false),
  menuModal = ref(false),
  terminalModal = ref(false),
  importStoreId = ref(0),
  terminalLoading = ref(false),
  terminalImportNewId = ref(false),
  terminalImportData = ref([]),
  withdrawModal = ref(false),
  total = ref(0),
  storeId = ref(0),
  brandId = ref(null),
  posTypes = ref([]),
  brands = ref([]),
  classifications = ref([]),
  cuisines = ref([]),
  additionals = ref([]),
  stickers = ref([]),
  pos = reactive({
    store_id: null,
    pos_type_id: null,
  }),
  storeFilter = ref([]),
  selectedRowKeys = ref([]),
  multipleModalVisible = ref(false),
  multipleMenuModalVisible = ref(false),
  modalVisible = ref(false),
  fileList = ref([]),
  walletTypes = {
    // p2p: 'P2P',
    shared: 'Общий',
    personal: 'Персональный',
  };
let timer = null;

const router = useRouter(),
  store = useStore(),
  filters = computed(() => store.getters.filters),
  user = computed(() => useStore().getters['user/user']),
  onSelectChange = changeableRowKeys => {
    selectedRowKeys.value = changeableRowKeys;
  },
  rowSelection = computed(() => {
    return null
    if (user.value.role !== 'admin') {
      return null
    }
    return {
      selectedRowKeys: unref(selectedRowKeys),
      onChange: onSelectChange,
      hideDefaultSelections: true,
      selections: null,
    };
  }),
  route = useRoute(),
  initialParams = {
    page: 1,
    'per-page': 10,
    sort: '',
    name: '',
  },
  queryParams = reactive({ ...initialParams }),
  columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 80,
    },
    // {
    //   title: 'Статус',
    //   dataIndex: 'status',
    //   key: 'status',
    //   sorter: true,
    //   slots: {
    //     customRender: 'status',
    //   },
    // },
    {
      title: 'Название филиала',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      width: 450,
      fixed: 'left',
      slots: {
        customRender: 'name',
      },
    },
    {
      title: 'Тип кошелька',
      dataIndex: 'wallet_type',
      sorter: true,
      width: 180,
      key: 'wallet_type',
      slots: { customRender: 'wallet_type' },
    },
    {
      title: 'Баланс',
      dataIndex: 'wallet',
      sorter: true,
      width: 140,
      key: 'wallet',
      slots: { customRender: 'wallet' },
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      sorter: true,
      width: 100,
      key: 'status',
      slots: { customRender: 'status' },
    },
    {
      title: 'Добавлен',
      dataIndex: 'added_on',
      sorter: true,
      width: 290,
      key: 'added_on',
      slots: { customRender: 'date' },
    },
    {
      title: 'Редактирован',
      dataIndex: 'updated_on',
      sorter: true,
      width: 290,
      key: 'updated_on',
      slots: { customRender: 'updated' },
    },
    {
      key: 'action',
      fixed: 'right',
      width: 70,
      slots: { customRender: 'action' },
    },
  ],
  // getOptionColor = (key, default_option) => {
  //   return key === default_option ? 'blue' : 'default'
  // },
  updateModal = (id) => {
    storeId.value = id;
    modalVisible.value = true;
  },
  updateStatus = (id, status) => {
    loading.value = true
    apiClient.post(`/store/update-status?id=${id}&status=${status}`).then(response => {
      if (response) {
        message.success('Статус успешно обновлен')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
      reload()
    });
  },
  updateParams = (params) => {
    Object.assign(queryParams, params)
    Object.keys(params).forEach((queryParam) => {
      switch (queryParam) {
        case 'brand_id':
          brandId.value = params.brand_id;
          break;
        case 'name':
          storeSearchName.value = params.name;
          break;
        case 'merchant':
          innSearchName.value = params.merchant;
          break;
      }
    })
  },
  tableChange = (pag, filters, sorter) => {
    if (typeof sorter.order == "string") {
      let sign = ''
      if (sorter.order === 'descend')
        sign = '-'
      updateParams({ sort: sign + sorter.field })
    }
  },
  searchStore = (e) => {
    if (e.target.value.length > 0 && e.target.value.length < 3)
      return;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      updateParams({ name: e.target.value })
    }, 800);
  },
  searchByInn = (e) => {
    if (e.target.value.length > 0 && e.target.value.length < 3)
      return;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      updateParams({ merchant: e.target.value })
    }, 800);
  },
  searchBrand = (e) => {
    updateParams({ brand_id: e })
  },
  showEditModal = (id) => {
    storeId.value = id
    editModal.value = true;
  },
  showEditAltModal = (id) => {
    storeId.value = id
    editAltModal.value = true;
  },
  showMenuModal = (id, name) => {
    storeId.value = id
    storeName.value = name
    menuModal.value = true;
  },
  showTerminalModal = (id) => {
    terminalModal.value = true;
    importStoreId.value = id
  },
  closeTerminalModal = () => {
    terminalModal.value = false;
    importStoreId.value = 0
    terminalImportData.value = []
  },
  importTerminals = () => {
    apiClient.post(`/terminal/import?store=${importStoreId.value}`, {
      terminals: terminalImportData.value
    }).then(() => {
      // console.log(res)
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      closeTerminalModal()
    })
  },
  uploadTerminals = info => {
    const status = info.file.status;
    if (status !== 'uploading') {
      // console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      if (info.file.response?.data?.saved) {
        message.success(`Кассы обновлены успешно`);
        closeTerminalModal()
      } else {
        if (info.file.response?.data?.items_updated) {
          terminalImportNewId.value = true
          message.success(`Некоторые кассы были обновлены`);
        }
        terminalImportData.value = info.file.response?.data?.data ?? []
      }
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  getImportUrl = () => {
    return process.env.VUE_APP_BACKEND_URL + '/cabinet/terminal/import-file?store=' + importStoreId.value
  },
  showInviteConfirm = (id, name) => {
    inviteModal.value = true
    inviteId.value = id
    inviteStoreName.value = name
  },
  hideInviteConfirm = () => {
    inviteModal.value = false
    inviteId.value = 0
    inviteStoreName.value = null
  },
  inviteConfirm = () => {
    inviteLoading.value = inviteId.value
    apiClient.post(`/store/send-invite?store=${inviteId.value}`).then(() => {
      notification.success({ message: 'Уведомления отправлены сотрудникам' })
      inviteLoading.value = 0
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      hideInviteConfirm()
      inviteLoading.value = 0
    });
  },
  copy = (id) => {
    apiClient.post(`/store/copy?store=${id}`).then(() => {
      notification.success({
        message: 'Копия заведении создана успешно'
      })
    }).catch(() => {
      notification.success({
        message: 'Не удалось создать копию'
      })
      // console.log(err)
    }).finally(() => {
      reload()
    });
  },
  showWithdrawModal = (id, name, user) => {
    if (user.role === 'manager') {
      storeId.value = id
      storeName.value = name
      withdrawModal.value = true;
    }
  },
  hideEditModal = () => {
    setTimeout(() => {
      editModal.value = false
    }, 100)
  },
  hideEditAltModal = () => {
    setTimeout(() => {
      editAltModal.value = false
    }, 100)
  },
  hideMenuModal = () => {
    setTimeout(() => {
      menuModal.value = false
    }, 100)
  },
  hideMultipleModal = () => {
    setTimeout(() => {
      multipleModalVisible.value = false
    }, 100)
  },
  hideWithdrawModal = () => {
    setTimeout(() => {
      withdrawModal.value = false
    }, 100)
  },
  goToStores = (id) => {
    router.push({ name: 'employee', query: { store_id: id } })
    localStore.set('app.menu.selectedKeys', ['__employee'])
  },
  goToReports = (id) => {
    router.push({ name: 'report', query: { store_id: id } })
    localStore.set('app.menu.selectedKeys', ['__report'])
  },
  formattedBalance = (balance) => {
    let formatter = new Intl.NumberFormat('RU', {
      style: 'currency',
      currency: 'UZS',
    });
    let formattedBalance = formatter.format(balance / 100)
    return formattedBalance.substring(0, formattedBalance.length - 4)
  },
  formattedDatetime = (date) => {
    date = date.replace(/ /g,"T")
    let d = new Date(date)
    const timeFormatter = new Intl.DateTimeFormat('ru-RU', {
      timeStyle: 'medium',
    })
    const dateFormatter = new Intl.DateTimeFormat('ru-RU')
    return dateFormatter.format(d) + ' ' + timeFormatter.format(d)
  },
  updatePos = () => {
    posLoading.value = true;
    return apiClient.post('/store/update-pos', pos).then(() => {
      notification.success({
        message: 'POS_TYPE_ID успешно обновлен',
      })
    }).catch(() => {
      // console.log('error')
    }).finally(() => {
      posLoading.value = false;
      posModal.value = false;
    })
  },
  closePos = () => {
    posModal.value = false;
  },
  getData = (params) => {
    loading.value = true
    return apiClient.get('/store', { params }).then(({data}) => {
      dataSource.value = data.data.items
      total.value = data.data.total
      posTypes.value = data.data.pos
      brands.value = data.data.brands
      classifications.value = data.data.classifications
      cuisines.value = data.data.cuisines
      additionals.value = data.data.additionals
      stickers.value = data.data.stickers
      storeFilter.value = data.data.stores
      loading.value = false
      modalKey.value++;
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
      store.commit('CHANGE_FILTER', { page: 'store', value: params })
    });
  },
  multipleUpdateModal = () => {
    multipleEditModalKey.value++
    multipleModalVisible.value = true
  },
  multipleMenuModal = () => {
    multipleMenuModalKey.value++
    multipleMenuModalVisible.value = true
  },
  clearSelection = () => {
    selectedRowKeys.value = []
  },
  selectAll = () => {
    const params = {...queryParams}
    return apiClient.get('/store/ids', {
      params,
    }).then(({data}) => {
      if (data['data']) {
        selectedRowKeys.value = data['data']
      }
    })
  },
  reload = () => {
    getData({
      ...queryParams,
    })
  };

const showTerminalIds = (record) => {
  if (user.value.role !== 'support')
    return [2,3].includes(parseInt(record.pos_type_id))

  return true
}

const getStateParams = () => {
  let stateParams = {}
  if (filters.value.store) {
    stateParams = filters.value.store
    Object.keys(stateParams).forEach(paramKey => {
      let _params = {}
      _params[paramKey] = stateParams[paramKey]
      updateParams(_params)
    })
  }
}

onMounted(() => {
  if (user.value.role !== 'admin')
    delete columns[0]

  getStateParams()

  if (route.query.brand_id) {
    brandId.value = route.query.brand_id
    updateParams({ brand_id: route.query.brand_id })
    return;
  }
  getData({
    ...queryParams,
  })
})

watch(selectedRowKeys, (value) => {
  showSelectAllButton.value = value.length === queryParams['per-page']
})

watch(queryParams, () => {
  const params = {
    ...route.query,
    ...queryParams,
  }
  router.push({
    name: 'store',
    query: params,
  })
  getData(params)
})

const query = {...toRefs(queryParams)}

</script>

<style>
.cursor-pointer {
  cursor: pointer;
}
[data-vb-theme="default"] .ant-table-thead > tr > th{
  background: none;
}
[data-vb-theme="default"] .ant-table-thead > tr > th:first-child,
[data-vb-theme="default"] .ant-table-thead > tr > th:nth-child(2),
[data-vb-theme="default"] .ant-table-thead > tr > th:nth-child(3),
[data-vb-theme="default"] .ant-table-thead > tr > th:last-child {
  background: #fff;
}

.store-name{
  display: block;
  float: left;
  line-height: 40px;
}
.store-logo{
  margin-right: 10px;
  width: 40px;
  height: 40px;
  float: left;
  border-radius: 4px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
[data-vb-theme="default"] .dropdown-action-button .ant-btn{
  border: none;
  border-radius: 32px !important;
  padding: 0 !important;
  background: none;
  transition: all 0s ease;
}
[data-vb-theme="default"] .dropdown-action-button .ant-btn:hover {
  transition: all 0s ease;
}
[data-vb-theme="default"] .dropdown-action-button .ant-btn:hover .more-icon-border {
  border-color: #78a3ff;
}
[data-vb-theme="default"] .ant-badge-status-dot {
  top: -2px;
  width: 10px;
  height: 10px;
}
[data-vb-theme="default"] .ant-badge-status-processing {
  background-color: #41b883;
}
[data-vb-theme="default"] .ant-badge-status-processing::after {
  border: 1px solid #41b883;
}
.more-icon-border {
  width: 30px;
  height: 30px;
  border: 2px solid #5a5c97;
  border-radius: 30px;
  font-size: 18px;
  float: right;
}
.terminal-statuses {
  display: flex;
  float: left;
  margin-right: 10px;
}
</style>
