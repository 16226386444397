import { createRouter, createWebHistory } from 'vue-router'
import DocumentationLayout from '@/layouts/Documentation'
import IntegrationLayout from '@/layouts/Integration'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import NProgress from 'nprogress'
import store from '@/store'

const router = createRouter({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      // VB:REPLACE-NEXT-LINE:ROUTER-REDIRECT
      redirect: '/store',
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
      },
      children: [
        // VB:REPLACE-START:ROUTER-CONFIG
        {
          path: 'dashboard',
          name: 'dashboard',
          meta: { title: 'Админ панель' },
          component: () => import('./views/dashboard'),
        },
        {
          path: 'settings',
          name: 'settings',
          meta: {
            title: 'Настройки аккаунта',
            hideBreadcrumbs: true
          },
          component: () => import('./views/settings'),
        },
        {
          name: 'brand',
          path: 'brand',
          props: true,
          meta: { title: 'Бренды' },
          component: () => import('./views/brand'),
        },
        {
          name: 'store',
          path: 'store',
          props: true,
          meta: { title: 'Филиалы' },
          component: () => import('./views/store'),
        },
        {
          name: 'terminal',
          path: 'terminal/:brand?/:store?',
          props: true,
          meta: { title: 'Кассы' },
          component: () => import('./views/store/terminal'),
        },
        {
          path: 'employee',
          name: 'employee',
          props: true,
          meta: { title: 'Сотрудники' },
          component: () => import('./views/employee'),
        },
        {
          name: 'claim',
          path: 'claim',
          props: true,
          meta: { title: 'Заявки' },
          component: () => import('./views/claim'),
        },
        {
          path: 'order',
          name: 'order',
          props: true,
          meta: { title: 'Создание заказа' },
          component: () => import('./views/order'),
        },
        {
          path: 'notification',
          name: 'notification',
          props: true,
          meta: { title: 'Рассылка' },
          component: () => import('./views/notification'),
        },
        {
          path: 'employee/archived',
          name: 'employee-archived',
          props: true,
          meta: { title: 'Архив сотрудников' },
          component: () => import('./views/employee/archived'),
        },
        {
          path: 'report/review/:status',
          name: 'review',
          props: true,
          meta: { title: 'Работа с отзывами' },
          component: () => import('./views/report/review'),
        },
        {
          path: 'report',
          name: 'report',
          props: true,
          meta: { title: 'Платежи' },
          component: () => import('./views/report/index'),
        },
        // {
        //   path: 'payments',
        //   name: 'payments',
        //   props: true,
        //   meta: { title: 'Платежи' },
        //   component: () => import('./views/report/payments'),
        // },
        {
          path: 'payment-analytics',
          name: 'payment-analytics',
          meta: { title: 'Отчет по платежам' },
          component: () => import('./views/report/analytics'),
        },
        {
          path: 'withdraw',
          name: 'withdraw',
          props: true,
          meta: { title: 'Вывод чаевых' },
          component: () => import('./views/report/withdraw'),
        },
        {
          path: 'income',
          name: 'income',
          props: true,
          meta: { title: 'Полученные чаевые' },
          component: () => import('./views/report/income'),
        },
        {
          path: 'users',
          name: 'users',
          meta: { title: 'Пользователи' },
          component: () => import('./views/users'),
        },
        {
          path: 'users/archived',
          name: 'users-archived',
          props: true,
          meta: { title: 'Архив пользователей' },
          component: () => import('./views/users/archived'),
        },
        {
          path: 'tokens',
          name: 'tokens',
          meta: { title: 'Токены' },
          component: () => import('./views/token'),
        },
        {
          name: 'employee-roles',
          path: 'employee/roles',
          meta: { title: 'Роли сотрудников' },
          component: () => import('./views/employee/role'),
        },
        {
          name: 'click-merchant',
          path: 'click-merchant',
          meta: { title: 'Click merchant' },
          component: () => import('./views/click-merchant'),
        },
        {
          name: 'pos-types',
          path: 'pos',
          meta: { title: 'Типы кассовых систем' },
          component: () => import('./views/pos'),
        },
        // {
        //   name: 'pos-system',
        //   path: 'pos-system',
        //   meta: { title: 'Мониторинг POS' },
        //   component: () => import('./views/pos-system'),
        // },
        {
          name: 'categories',
          path: 'categories/:brand',
          meta: {
            title: 'Категории',
            hideBreadcrumbs: true
          },
          component: () => import('./views/store/menu/categories'),
        },
        {
          name: 'products',
          path: 'products/:brand/:category?',
          meta: {
            title: 'Продукты',
            hideBreadcrumbs: true
          },
          component: () => import('./views/store/menu/products'),
        },
        {
          name: 'modifiers',
          path: 'brand/:brand/category/:category/product/:product/modifiers',
          meta: {
            title: 'Модификации',
            hideBreadcrumbs: true
          },
          component: () => import('./views/store/menu/modifiers'),
        },
        {
          name: 'orders',
          path: 'orders/:store',
          meta: {
            title: 'Меню',
            hideBreadcrumbs: true
          },
          component: () => import('./views/store/menu/orders'),
        },
        {
          name: 'tables',
          path: 'tables/:store',
          meta: {
            title: 'Table',
            hideBreadcrumbs: true
          },
          component: () => import('./views/store/menu/tables'),
        },
        {
          name: 'hall',
          path: 'hall/:store',
          meta: {
            title: 'Hall',
            hideBreadcrumbs: true
          },
          component: () => import('./views/store/menu/hall'),
        },
        // {
        //   name: 'receipt',
        //   path: 'receipt',
        //   meta: { title: 'Отправка фискольного чека' },
        //   component: () => import('./views/receipt/index'),
        // },
        {
          name: 'payment-systems',
          path: 'payment-systems',
          props: true,
          meta: { title: 'Платежные системы' },
          component: () => import('./views/payment-system'),
        },
        {
          name: 'documentations',
          path: 'documentations',
          props: true,
          meta: { title: 'Документация' },
          component: () => import('./views/documentation'),
        },
        {
          name: 'integrations',
          path: 'integrations',
          props: true,
          meta: { title: 'Интеграция' },
          component: () => import('./views/integration'),
        },
        {
          name: 'integration-sections',
          path: 'integration-sections/:page',
          props: true,
          meta: { title: 'Контент' },
          component: () => import('./views/integration/section'),
        },
        {
          name: 'sections',
          path: 'sections/:page',
          props: true,
          meta: { title: 'Контент' },
          component: () => import('./views/documentation/section'),
        },
        {
          name: 'references',
          path: 'references',
          children: [
            {
              name: 'modifier',
              path: 'modifier',
              meta: { title: 'Модификации' },
              component: () => import('./views/references/modifier'),
            },
            {
              name: 'modifier-group',
              path: 'modifier/group',
              meta: { title: 'Группы модификаций' },
              component: () => import('./views/references/modifier-group'),
            },
            {
              name: 'sticker',
              path: 'sticker',
              meta: { title: 'Стикеры' },
              component: () => import('./views/references/sticker'),
            },
            {
              name: 'cuisine',
              path: 'cuisine',
              meta: { title: 'Кухня и блюда' },
              component: () => import('./views/references/cuisine'),
            },
            {
              name: 'classification',
              path: 'classification',
              meta: { title: 'Типы заведений' },
              component: () => import('./views/references/classification'),
            },
            {
              name: 'additional',
              path: 'additional',
              meta: { title: 'Дополнительные' },
              component: () => import('./views/references/additional'),
            },
            {
              name: 'unit',
              path: 'unit',
              meta: { title: 'Ед. измерения' },
              component: () => import('./views/references/unit'),
            },
            {
              name: 'language',
              path: 'language',
              meta: { title: 'Языковые настройки' },
              component: () => import('./views/references/language'),
            },
          ],
        },
        // VB:REPLACE-END:ROUTER-CONFIG
      ],
    },

    // System Pages
    {
      path: '/auth',
      component: AuthLayout,
      redirect: 'auth/login',
      children: [
        {
          path: '/auth/404',
          name: 'route404',
          meta: {
            title: 'Error 404',
          },
          component: () => import('./views/auth/404'),
        },
        {
          path: '/auth/500',
          meta: {
            title: 'Error 500',
          },
          component: () => import('./views/auth/500'),
        },
        {
          path: '/auth/login',
          name: 'login',
          meta: {
            title: 'Sign In',
          },
          component: () => import('./views/auth/login'),
        },
        {
          path: '/auth/register',
          name: 'register',
          meta: {
            title: 'Sign Up',
          },
          component: () => import('./views/auth/register'),
        },
        {
          path: '/auth/sign/:user/:account',
          name: 'sign',
          meta: {
            title: 'Sign',
            authRequired: false
          },
          component: () => import('./views/auth/sign'),
        },
        {
          path: '/auth/forgot-password',
          meta: {
            title: 'Forgot Password',
          },
          component: () => import('./views/auth/forgot-password'),
        },
        {
          path: '/auth/lockscreen',
          meta: {
            title: 'Lockscreen',
          },
          component: () => import('./views/auth/lockscreen'),
        },
      ],
    },
    {
      path: '/documentation/:page?',
      name: 'documentation',
      component: DocumentationLayout,
      meta: {
        authRequired: false
      }
    },
    {
      path: '/integration/:page?',
      name: 'integration',
      component: IntegrationLayout,
      meta: {
        authRequired: false
      }
    },
    // Redirect to 404
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'route404' },
    },
  ],
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  setTimeout(() => {
    NProgress.done()
  }, 300)

  if (to.matched.some(record => record.meta.authRequired)) {
    if (!store.state.user.authorized) {
      next({
        path: '/auth/login',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
